<template>
    <div>
        <div class="SecondTitleRegistration" style="display: flex;justify-content: center;">
            <span>
                {{ $t('registration.counterth') }}:{{ this.storeName }}
            </span>
        </div>
        <van-cell-group style="margin-top: 20px">
            <van-form :onChange="saveInfo()">
                <van-field required :error-message="errMessageSalutation" @blur="validatorSalutation" v-model="info.title">
                    <template #input>
                        <van-dropdown-menu>
                            <van-dropdown-item v-model="info.title" :options="titleOption"
                                :title="titleOption.find(i => i.value == info.title)?.text || $t('registration.salutation')">
                            </van-dropdown-item>
                        </van-dropdown-menu>
                    </template>
                </van-field>
                <van-field required v-model="info.firstName" :placeholder="this.$t('registration.formValueFirstName')"
                    :error-message="errMessageFirstName" @blur="validatorFirstName" @input="validatorFirstName"
                    @focus="validatorSalutation();" />
                <van-field required v-model="info.lastName" :placeholder="this.$t('registration.formValueLastName')"
                    :error-message="errMessageLastName" @blur="validatorLastName" @input="validatorLastName"
                    @focus="validatorSalutation(); validatorFirstName();" />
                <div style="display: flex;align-items: center;">
                    <div style="margin-left:10px">
                        <div>+66</div>
                    </div>
                    <van-field type="number" style="border-bottom: 1px solid #f5f6f8" v-model="info.mobile"
                        :error-message="errMessagePhone" disabled
                        :placeholder="this.$t('registration.formValueMobileNumberth')" />
                </div>
                <van-field v-model="info.email" :placeholder="this.$t('registration.formValueMobileEmailth')"
                    :error-message="errMessageEmail"
                    @focus="validatorSalutation(); validatorFirstName(); validatorLastName(); validatorPhone()"
                    @blur="validatorEmail" />
                <van-field class="birth" :error-message="errMessageBirth">
                    <template #input>
                        <BirthView
                            @change="validatorSalutation(); validatorFirstName(); validatorLastName(); validatorPhone()"
                            :requiredDay="true" :requiredMonth="true" :birth="info.birth" />
                    </template>
                </van-field>

                <div class="second-tips">
                    <div>
                        <input class="agreeCheckBox" type="checkbox" v-model="info.agreeCondition1"
                            @change="clickAgree()" />
                    </div>
                    <div class="agreeStatement" v-html='$t("registration.frontierNewSub1").format(otherBrands,this.brandConfig.display)'/>
                </div>
                <div class="second-tips">
                    <div>
                        <input class="agreeCheckBox" type="checkbox" v-model="info.agreeCondition3" />
                    </div>
                    <!-- <div v-if="brandConfig.name == 'tomford' || brandConfig.name == 'lelabo'" class="agreeStatementMandatory"
                        v-html='$t("enterNumber.readPrivacyPolicyForTFAndLL").format(brandConfig.display, privacyPolicyLink)'>
                    </div> -->
                    <div class="agreeStatementMandatory"
                        v-html='$t("enterNumber.readPrivacyPolicy").format(brandConfig.display, privacyPolicyLink)'>
                    </div>
                </div>
                <!-- Statement button  -->
                <div class="second-tips" v-if="showTermsAndConditions">
                    <div>
                        <input class="agreeCheckBox" type="checkbox" v-model="info.agreeConditions" />
                    </div>
                    <!-- <div class="agreeStatement">
                        {{ $t("registration.statementButton1") }}
                        <a :href="brandConfig.termsAndConditions">{{ brandConfig.display }}
                            {{ $t("registration.loyaltyProgram") }}</a>
                    </div> -->
                    <div class="agreeStatementMandatory"
                        v-html='$t("registration.termsAndConditions").format(brandConfig.display, termsAndConditionsLink)'>
                    </div>
                </div>
                <div style="margin-top: 20px">
                    <van-button :disabled="submitDisabled || addingCustorm" size="large" round class="button-submit" @click="submit()">
                        {{ $t("registration.submit") }}
                    </van-button>
                </div>
            </van-form>
        </van-cell-group>
    </div>
</template>

<script>
import {
    Button, Cell, CellGroup, DropdownItem, DropdownMenu,
    Field, Switch, Checkbox, CheckboxGroup, Form, Radio, RadioGroup, DatetimePicker, Popup, Dialog, Toast
} from 'vant';
import { getSession, relateAddOrUpdate, getAuthorizeUrl } from '@/api/eformApi';
import mixins from "./../mixin/Registration.js";
import { zipEncode } from "@/utils/zipcoder";
export default {
    name: "Registration",
    mixins: [mixins],
    components: {
        [Popup.name]: Popup,
        [DatetimePicker.name]: DatetimePicker,
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio,
        [Form.name]: Form,
        [CellGroup.name]: CellGroup,
        [Checkbox.name]: Checkbox,
        [CheckboxGroup.name]: CheckboxGroup,
        [Switch.name]: Switch,
        [Cell.name]: Cell,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
        [Button.name]: Button,
        [Field.name]: Field,
        [Dialog.Component.name]: Dialog.Component,
        BirthView: () => import('./../components/BirthView'),
    },
    props: {
    },
    created() { 
        console.log("Registration",this)
    },
    mounted() {
        if (localStorage.getItem('infoTH')) {
            this.info = JSON.parse(localStorage.getItem('infoTH'))
        }

        console.log(localStorage.getItem('enterNumberInfo'))
        if (localStorage.getItem('enterNumberInfo')) {
            this.info.mobile = localStorage.getItem('enterNumberInfo')
        } else {
            this.$router.push({ name: 'NotFound' })
        }
    },
    data() {
        return {
            show: true,
            firstName: '',
            info: {
                title: '',
                firstName: '',
                lastName: '',
                birth: {
                    day: '',
                    month: '',
                    year: ''
                },
                mobile: '',
                othmobile: '',
                email: '',
                gender: '',
                age: '',
                race: '',
                // address: '',
                // address2: '',
                // city: '',
                // state: '',
                // postCode: '',
                subscribeAll: false,
                subscribeEmail: false,
                subscribeMessage: false,
                subscribeCall: false,
                subscribePost: false,
                agreeConditions: false,
                agreeSubscribeStatement: false,
                agreeCondition1: false,
                agreeCondition2: false,
                agreeCondition3: false,
                noneUser: this.$route.params.secondTitle,
                myPattern: /^$|^([0-9]{10})+$/,
                languageCode: '',
                bama: '',
                salutation: ''
            },
            firstNameParten: /^((13[0-9])|(17[0-1,6-8])|(15[^4,\\D])|(18[0-9]))\d{8}$/,
            errMessageFirstName: '',
            errMessageLastName: '',
            errMessageEmail: '',
            errMessageSalutation: '',
            errMessagePhone: '',
            errMessageOphone: '',
            errMessageBirth: '',
            secondTitlehidden: false,
            dropd: '',
            bload: false,
            addingCustorm: false,
            tpid: this.$route.params.tpid
        };
    },
    computed: {
        otherBrands() { 
            return `<span><a href="https://www.elcompanies.com/en/our-brands">${this.$t("registration.otherBrands")}</a></span>`;
        },
        titleOption() {
            return [
                { text: this.$t('registration.khun'), value: 'Khun' },
                { text: this.$t('registration.mr'), value: 'Mr.' },
                { text: this.$t('registration.mrs'), value: 'Mrs.' },
                { text: this.$t('registration.miss'), value: 'Miss.' }
            ]
        },
        showTermsAndConditions() {
            return this.brandConfig.termsAndConditions != ''
        },
        submitDisabled() {
            return !(this.info.agreeCondition3) || (this.showTermsAndConditions && !this.info.agreeConditions)
        },
        privacyPolicyLink() {
            if (this.brandConfig.privacyPolicy) {
                return `<span><a href="${this.brandConfig.privacyPolicy}">Privacy Policy</a></span>`;
            } else {
                return `<span>Privacy Policy</span>`;
            }
        },
        termsAndConditionsLink() {
            return `<a href="${this.brandConfig.termsAndConditions}">${this.$t("registration.loyaltyTermsAndCondition")}</a>`;
        }
    },
    watch: {
        'info.subscribeCall': function () {
            if (!this.info.subscribeCall) {
                this.info.subscribeAll = false
            }
            if (this.info.subscribeCall && this.info.subscribeEmail && this.info.subscribeMessage && this.info.subscribePost) {
                this.info.subscribeAll = true
            }
        },
        'info.subscribeEmail': function () {
            if (!this.info.subscribeEmail) {
                this.info.subscribeAll = false
            }
            if (this.info.subscribeCall && this.info.subscribeEmail && this.info.subscribeMessage && this.info.subscribePost) {
                this.info.subscribeAll = true
            }
        },
        'info.subscribeMessage': function () {
            if (!this.info.subscribeMessage) {
                this.info.subscribeAll = false
            }
            if (this.info.subscribeCall && this.info.subscribeEmail && this.info.subscribeMessage && this.info.subscribePost) {
                this.info.subscribeAll = true
            }
        },
        'info.subscribePost': function () {
            if (!this.info.subscribePost) {
                this.info.subscribeAll = false
            }
            if (this.info.subscribeCall && this.info.subscribeEmail && this.info.subscribeMessage && this.info.subscribePost) {
                this.info.subscribeAll = true
            }
        },
    },
    methods: {
        validatorFirstName() {
            const reg = /^[\u0E00-\u0E7F]{0,}$/
            const enreg = /^[A-Za-z]{0,}$/
            if (this.info.firstName == '' || this.info.firstName.trim() == '') {
                this.errMessageFirstName = this.$t('registration.rulerFirstname');
            } else if (this.$route.params.countryPath == 'TH') {
                if (!reg.test(this.info.firstName) && this.$root.$i18n.locale == 'th') {
                    this.errMessageFirstName = this.$t('registration.rulerFirstname1');
                    // 非泰文替换成''
                    this.info.firstName = this.info.firstName.replace(/[^\u0E00-\u0E7F]/g, '')
                } else if (!enreg.test(this.info.firstName) && this.$root.$i18n.locale == 'en') {
                    this.errMessageFirstName = this.$t('registration.rulerFirstname1');
                    // 非英文替换成''
                    this.info.firstName = this.info.firstName.replace(/[^A-Za-z]/g, '')
                } else {
                    this.errMessageFirstName = ''
                }
            } else {
                this.errMessageFirstName = ''
            }
        },
        validatorSalutation() {
            if (this.info.title == '' || this.info.title.trim() == '') {
                this.errMessageSalutation = this.$t('registration.rulerSalutation');
            } else {
                this.errMessageSalutation = ''
            }
        },
        validatorLastName() {
            const reg = /^[\u0E00-\u0E7F]{0,}$/
            const enreg = /^[A-Za-z]{0,}$/
            if (this.info.lastName == '' || this.info.lastName.trim() == '') {
                this.errMessageLastName = this.$t('registration.rulerLastname');
            } else if (this.$route.params.countryPath == 'TH') {
                if (!reg.test(this.info.lastName) && this.$root.$i18n.locale == 'th') {
                    this.errMessageLastName = this.$t('registration.rulerLastname1');
                    // 非泰文替换成''
                    this.info.lastName = this.info.lastName.replace(/[^\u0E00-\u0E7F]/g, '')
                } else if (!enreg.test(this.info.lastName) && this.$root.$i18n.locale == 'en') {
                    this.errMessageLastName = this.$t('registration.rulerLastname1');
                    // 非英文替换成''
                    this.info.lastName = this.info.lastName.replace(/[^A-Za-z]/g, '')
                } else {
                    this.errMessageLastName = ''
                }
            } else {
                this.errMessageLastName = ''
            }
        },
        validatorPhone() {
            // var num = this.info.mobile.length
            // if(num>=10){
            //     this.info.mobile = this.info.mobile.slice(0,10)
            // }
            if (this.$route.params.countryPath == 'MY') {
                if (this.info.mobile == '' || this.info.mobile.trim() == '') {
                    this.errMessagePhone = this.$t('registration.mobileAlert');
                } else {
                    var mobileF = this.info.mobile.substr(0, 1)
                    if (mobileF != '1') {
                        this.errMessagePhone = this.$t('registration.mobileAlert')
                    } else {
                        this.errMessagePhone = ''
                    }
                }
            } else {
                if (this.info.mobile == '' || this.info.mobile.trim() == '') {
                    this.errMessagePhone = ''
                } else {
                    this.errMessagePhone = ''
                }
            }
        },
        validatorOPhone() {
            if (this.$route.params.countryPath == 'MY') {
                var num = this.info.othmobile.length
                if (num >= 10) {
                    this.info.othmobile = this.info.othmobile.slice(0, 10)
                }
                if (this.info.othmobile != null && this.info.othmobile != '') {
                    var othermobileF = this.info.othmobile.substr(0, 1)
                    if (othermobileF == '0') {
                        this.errMessageOphone = this.$t('registration.otherMobileAlert')
                    }
                } else {
                    this.errMessageOphone = ''
                }
            }
        },
        validatorEmail() {
            if (this.$route.params.countryPath == 'MY') {
                const reg = /^[0-9A-Za-z_]+([-+.][0-9A-Za-z_]+)*@[0-9A-Za-z_]+([-.][0-9A-Za-z_]+)*\.[0-9A-Za-z_]+([-.][0-9A-Za-z_]+)*$/;
                if (!reg.test(this.info.email) || this.info.email == '' || this.info.email.trim() == '') {
                    this.errMessageEmail = this.$t('registration.rulerEmail');
                    return false;
                } else {
                    this.errMessageEmail = '';
                    return true;
                }
            } else {
                const reg = /^[0-9A-Za-z_]+([-+.][0-9A-Za-z_]+)*@[0-9A-Za-z_]+([-.][0-9A-Za-z_]+)*\.[0-9A-Za-z_]+([-.][0-9A-Za-z_]+)*$/;
                if (this.info.email == '') {
                    this.errMessageEmail = '';
                    return true;
                }
                else if (!reg.test(this.info.email) || this.info.email.trim() == '') {
                    this.errMessageEmail = this.$t('registration.rulerEmail');
                    return false;
                } else {
                    this.errMessageEmail = '';
                    return true;
                }
            }
        },
        validatorBirth() {
            if (this.$route.params.countryPath == 'MY') {
                if (this.info.birth.day == '' || this.info.birth.month == '' || this.info.birth.year == '') {
                    this.errMessageBirth = this.$t('registration.rulerBirth');
                } else {
                    this.errMessageBirth = '';
                }
            } else {
                if (this.info.birth.day == '') {
                    this.errMessageBirth = this.$t('registration.rulerBirthday');
                } else if (this.info.birth.month == '') {
                    this.errMessageBirth = this.$t('registration.rulerBirthmonth');
                } else {
                    this.errMessageBirth = '';
                }
            }
        },
        submit() {
            console.log(this.info)
            this.validatorSalutation()
            this.validatorFirstName()
            this.validatorLastName()
            this.validatorBirth()
            this.validatorPhone()
            this.validatorEmail()
            if (this.errMessageSalutation != '' || this.errMessageBirth != '' || this.errMessageEmail != '' || this.errMessageFirstName != '' || this.errMessageLastName != '' || this.errMessagePhone != '') {
                return
            }
            this.info.storeId = this.storeId
            this.info.brand = this.$route.params.brand
            this.addingCustorm = true
            this.addCustorm()
        },
        saveInfo() {
            localStorage.setItem('infoTH', JSON.stringify(this.info))
        },
        allClick() {
            this.info.subscribeEmail = this.info.subscribeAll
            this.info.subscribeMessage = this.info.subscribeAll
            this.info.subscribeCall = this.info.subscribeAll
            this.info.subscribePost = this.info.subscribeAll
        },
        clickAgree() {
            if (this.info.agreeCondition1 == false && this.info.agreeCondition2 == false) {
                this.info.subscribeAll = false
                this.info.subscribeEmail = false
                this.info.subscribeMessage = false
                this.info.subscribeCall = false
                this.info.subscribePost = false
            }
        },
        addCustorm() {
            this.addingCustorm = true
            getSession({
                ...this.info,
                brand: this.$route.params.brand,
                country: this.$route.params.countryPath,
            }).then(res => {
                if (res.success) {
                    relateAddOrUpdate({
                        ...this.info,
                        storeId: this.storeId,
                        brand: this.$route.params.brand,
                        country: this.$route.params.countryPath,
                        languageCode: this.$root.$i18n.locale.toUpperCase(),
                        sessionKey: res.data,
                        txNo: this.txNo,
                        tpid: this.tpid,
                    }).then(res => {
                        this.addingCustorm = false
                        if (res.success) {
                            //注册成功，判断是否已绑定三方
                            if(res.data.tpid) { //binded, social login
                                this.$router.push({
                                    name: 'QRcodes',
                                    params: {
                                        custId: res.data.frontiersVipNo,
                                        storeId: this.storeId,
                                        lang: this.$route.params.lang,
                                        brand: this.$route.params.brand,
                                        country: this.$route.params.countryPath,
                                        tpid: res.data.tpid,
                                        isOptin: this.info.agreeCondition1,
                                        bindConfirmMessage: `${res.data.thirdPart} binding is completed.`
                                    },
                                    query: {
                                        storeId: this.$route.query.storeId,
                                    }
                                })
                            } else {
                                Dialog.confirm({
                                    confirmButtonText: this.$t("registration.confirmLineBindingYes"),
                                    cancelButtonText: this.$t("registration.confirmLineBindingNo"),
                                    message: this.$t("registration.confirmLineBinding")
                                })
                                .then(() => {
                                    //on Yes, redirect to third-part authorize page
                                    getAuthorizeUrl({
                                        thirdPart: 'line',
                                        country: this.$route.params.countryPath,
                                        brand: this.$route.params.brand,
                                        hostPath: window.location.href.split('#')[0],
                                        consumerId: res.data.frontiersVipNo,
                                        nextRouter: zipEncode(JSON.stringify({
                                            name: 'QRcodes',
                                            params: {
                                                custId: res.data.frontiersVipNo,
                                                storeId: this.storeId,
                                                lang: this.$route.params.lang,
                                                brand: this.$route.params.brand,
                                                country: this.$route.params.countryPath,
                                                countryPath: this.$route.params.countryPath,
                                                // 用于QRcode页面展示
                                                isOptin: this.info.agreeCondition1,
                                                bindConfirmMessage: `line binding is completed.`
                                            },
                                            query: {
                                                storeId: this.$route.query.storeId,
                                            }
                                        }))
                                    }).then((authRes) => {
                                        if (authRes.success) {
                                            console.log(`${authRes.data.tpid} redirect to line authorize url: ${authRes.data.authorizeUrl}`)
                                            window.location.href = authRes.data.authorizeUrl
                                        } else {
                                            console.log(authRes.errMsg)
                                            Toast.fail({
                                                type: "fail",
                                                message: authRes.errMsg,
                                            });
                                        }
                                    }).catch((err) => {
                                        console.log(err)
                                        Toast.fail({
                                            type: "fail",
                                            message: 'Line Authorize url get failed',
                                        });
                                    })
                                }).catch(()=>{
                                    this.$router.push({
                                        name: 'QRcodes',
                                        params: {
                                            custId: res.data.frontiersVipNo,
                                            storeId: this.storeId,
                                            lang: this.$route.params.lang,
                                            brand: this.$route.params.brand,
                                            country: this.$route.params.countryPath
                                        },
                                        query: {
                                            storeId: this.$route.query.storeId,
                                        }
                                    })
                                })
                            }
                        } else if (res.data.exist) {
                            Dialog.alert({
                                message: this.$t('registration.alreadyRP'),
                                confirmButtonText: this.$t('registration.confirm')
                            })
                            this.addingCustorm = false
                            return;
                        } else {
                            Toast.fail(this.$t('common.error'))
                        }
                    }).catch(() => {
                        Toast.fail(this.$t('common.error'))
                    });
                } else {
                    Toast.fail(this.$t('common.error'))
                }
            }).catch(() => {
                Toast.fail(this.$t('common.error'))
            });
        }
    }
}
</script>

<style scoped>
* {
    font-family: Helvetica-Regular !important;
}

input.agreeCheckBox {
    transform: scale(1.5);
    margin-top: 33%
}

.agreeStatementMandatory::before {
    padding-right: 2px;
    color: #ee0a24;
    font-size: 14px;
    content: "*";
    z-index: 100;
}

 .agreeStatementMandatory {
    margin-left: 10px;
}

.agreeStatement {
    margin-left: 10px;
}

.birth {
    padding: 10px 0px;
}

.second-tips {
    display: inline-flex;
    font-size: 14px;
    font-family: Helvetica-Regular;
    font-weight: 400;
    margin-top: 20px;
    line-height: 20px;
    width: 100%;
}

.button-submit {
    background: black;
    color: white;
    border-radius: 7px;
    margin-top: 10px;
}

/*下拉框样式*/
>>>.van-dropdown-menu {
    width: 100%;
}

>>>.van-dropdown-menu__title {
    /* position: unset;
        color: #afafaf; */
    font-size: 14px;
}

>>>.van-dropdown-menu__item {
    justify-content: left;
}

>>>.van-dropdown-menu__bar {
    height: unset;
    box-shadow: unset;
}

>>>.van-dialog {
    border-radius: 5px;
}

/*>>> .van-popup--top {*/
/*    width: 90%;*/
/*    margin: 0 5%;*/
/*}*/
/*>>> .van-dropdown-item__option {*/
/*    margin-left: 10px;*/
/*}*/
>>>.van-checkbox {
    /* margin-top: -10% */
}
</style>

<style lang="less" scoped>
.age .van-radio--horizontal {
    margin-bottom: 5px !important;
}

/deep/ .item1::before {
    position: absolute;
    left: -4px;
    top: 0px;
    color: #ee0a24;
    font-size: 14px;
    content: '*';
}

/deep/ .item2::before {
    position: absolute;
    left: 33%;
    top: 0px;
    color: #ee0a24;
    font-size: 14px;
    content: '*';
}
</style>
